<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">EQUIPMENT MONITORING</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form2" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md3>
            <v-select
              v-if="!alert"
              outlined
              class="mx-2"
              v-model="category_id"
              dense
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              :rules="rules.combobox_rule"
              @change="selected_category"
            ></v-select>
            <v-select
              outlined
              v-if="!is_forbidden && !alert"
              class="mx-2"
              v-model="selection"
              dense
              label="Selection"
              :items="['Branch','Regional','Territory']"
              :rules="rules.combobox_rule"
              @change="selected_category"
            ></v-select>
            <v-select
              v-if="!alert"
              outlined
              class="mx-2"
              v-model="branch_id"
              dense
              label="Branch"
              :items="branch_items"
              item-value="id"
              item-text="branch_code"
              :rules="rules.combobox_rule"
              @change="selected_branch"
            ></v-select>
            <v-select
              v-if="!alert"
              outlined
              class="mx-2"
              v-model="transmital_no"
              dense
              label="Transmital #"
              :items="transmital_no_items"
              item-value="transmital_no"
              item-text="transmital_no"
              :rules="rules.combobox_rule"
              @change="selected_transmital"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <Equipment :key="this.key" :data_items="data_items" :category_items="category_items"
                       :branch_items="branch_items" :category_id="category_id"
                       :branch_id="branch_id"
                       :equipment="equipment" :equipment_code="equipment_code"
                       :equipment_components="equipment_components"
                       v-on:response="selected_transmital"
            ></Equipment>
          </v-flex>
        </v-layout>
      </v-form>
    </v-card>
    <v-dialog v-model="is_change_bank_details" persistent max-width="30%">
      <TransferData :key="key" :details="details" v-on:response="on_change"></TransferData>
      <v-btn color="error" @click="is_change_bank_details = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose, mdiSwapHorizontal} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import TransferData from '@/views/view_interface/inventory_monitoring/TransferData'
  import Equipment from '@/views/file_interface/search/components_inventory/Equipment'

  const initialState = () => {
    return {
      alert: false,
      inventory_particulars_id: 1,
      category_id: 0,
      branch_id: 0,
      selection: '',
      total_amount: '0.00',
      is_forbidden: true,
      headers: [
        {text: 'ID', value: 'id', sortable: false},
        {text: 'Trans. #', value: 'transmital_no', sortable: false},
        {text: 'Category', value: 'category.category', sortable: false},
        {text: 'Branch', value: 'branch.branch_code', sortable: false},
        {text: 'Data', value: 'equipment', sortable: false},
        {text: 'Details', value: 'equipment_brand_description', sortable: false},
        {text: 'Serial #', value: 'equipment_serial_no', sortable: false},
        {text: 'Description', value: 'equipment_model_color', sortable: false},
        {text: 'Date Procure', value: 'equipment_date_procure', sortable: false},
        {text: 'Date Released', value: 'equipment_date_release', sortable: false},
        {text: 'Price', value: 'equipment_unit_price', sortable: false},
        {text: 'Quantity', value: 'equipment_unit_quantity', sortable: false},
        {text: 'Total', value: 'total', sortable: false},
        {text: 'Remarks', value: 'remarks', sortable: false},
        {text: 'Action', value: 'action', sortable: false},
      ],
      data_items: [],
      branch_items: [],
      category_items: [],

      key: 0,
      is_change_bank_details: false,
      details: {},

      equipment: '',
      equipment_code: '',
      equipment_components: '',

      transmital_no: '',
      transmital_no_items: [],
    }
  }
  export default {
    components: {
      snackBarDialog,
      TransferData,
      Equipment,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose, mdiSwapHorizontal
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('inventory_particulars', ['initialize_inventory_monitoring']),
      ...mapActions('inventory', ['list_of_inventory_by_particulars', 'list_of_branch_have_inventory', 'list_of_transmital_in_branch_have_inventory']),
      initialize_data() {
        this.initialize_inventory_monitoring({
          inventory_particulars_id: this.inventory_particulars_id
        })
          .then(response => {
            this.category_items = response.data

            this.category_id = -1
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      async selected_category() {
        this.is_forbidden = true
        if (this.category_id === 4 || this.category_id === 3) {
          this.is_forbidden = false
        } else {
          this.selection = ''
        }
        this.transmital_no = ''
        this.transmital_no_items = []
        this.data_items = []
        this.branch_id = 0
        await this.list_of_branch_have_inventory({
          category_id: this.category_id,
          inventory_particulars_id: this.inventory_particulars_id,
          is_region: this.selection === 'Regional' ? 1 : 0,
          is_territory: this.selection === 'Territory' ? 1 : 0,
        })
          .then(response => {
            this.branch_items = response.data
            this.branch_items.splice(0, 0, {
              id: -1,
              branch_code: 'All'
            })
          })
          .catch(error => {
            console.log(error)
          })
      },
      async selected_branch() {
        this.transmital_no = ''
        this.transmital_no_items = []
        this.data_items = []
        if (this.branch_id === -1) {
          this.transmital_no_items.splice(0, 0, {
            id: -1,
            transmital_no: 'All'
          })
          this.transmital_no = -1
        } else {
          await this.list_of_transmital_in_branch_have_inventory({
            category_id: this.category_id,
            branch_id: this.branch_id,
            inventory_particulars_id: this.inventory_particulars_id,
          })
            .then(response => {
              this.transmital_no_items = response.data
              this.transmital_no_items.splice(0, 0, {
                id: -1,
                transmital_no: 'All'
              })
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      selected_transmital() {
        this.data_items = []
        this.list_of_inventory_by_particulars({
          category_id: this.category_id,
          branch_id: this.branch_id,
          inventory_particulars_id: this.inventory_particulars_id,
          transmital_no: this.transmital_no,
        })
          .then(response => {
            this.data_items = response.data
            var tto = 0;
            this.data_items.forEach(function (item) {
              tto += parseFloat(item.land_value)
            });
            this.total_amount = this.formatPrice(tto)
          })
          .catch(error => {
            console.log(error)
          })
      },
      transfer(value) {
        this.key++
        this.details = value
        this.is_change_bank_details = true
      },
      on_change(value) {
        var color = 'error'
        if (value.status === 200) {
          color = 'success'
        }
        this.change_snackbar({
          show: true,
          color: color,
          text: value.data,
        })
        this.is_change_bank_details = false
      },
    },
  }
</script>
